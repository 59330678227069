import React, { useEffect, useState } from "react";


const General = () => {
  const [posts, setPosts] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/general/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);

  return (
    <>
     <div style={{height: "90px" }}></div>
      <div className="container mt-4">
        {posts.map((i) => {
          return (
            <div key={i.id} dangerouslySetInnerHTML={{ __html: i.name }}></div>
          );
        })}
      </div>
    </>
  );
};

export default General;