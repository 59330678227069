import React, { useState, useEffect } from "react";
// import "./Ilmiy_K.css";
import Table from "react-bootstrap/Table";
import { useParams } from "react-router-dom";

const Counsil = () => {
     const { id } = useParams([]);

  const [docs, setDocs] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/council/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDocs(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, id]);
  return (
  <>
    <div style={{ height: "90px" }}></div>
      <div className="container" style={{ marginTop: "50px" }}>
        <Table striped bordered hover>
          <thead className="thead">
            <tr>
              <th>#</th>
              <th>Nomi</th>
            </tr>
          </thead>
          <tbody>
            {docs.map((kengash) => {
              return (
                <tr className="table_tr" key={kengash.id}>
                  <td>{kengash.oder_number}</td>
                  <td>
                    {" "}
                    <div
                      className="text-k"
                      key={kengash.id}
                      dangerouslySetInnerHTML={{ __html: kengash.info }}
                    ></div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
  </>
  )
}

export default Counsil;




      

