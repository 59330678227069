import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
// import "./Cards.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";


const News = () => {
    const [news, setNews] = useState([]);
  
    const lang = localStorage.getItem("lang") || "uz";
    useEffect(() => {
      fetch(`https://science.nuu.uz/${lang}/application/newsfull/`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setNews(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, [lang]);
  
    AOS.init();
    AOS.refresh();
    return (
      <>
    <div>
          {/* <div style={{ background: "rgb(37, 39, 52)", height: "90px", width: "100%" }}></div> */}
          <div class="popular_program_area section__padding">
        <div class="container">
          
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="row">
                       
                          {news.reverse().map((item) => (
                        <div class="col-lg-3 col-md-6" key={item.id}>
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src={item.icon} alt="" style={{maxHeight: "180px"}}/>
                                </div>
                                <div class="program__content">
                                    {/* <span>Agriculture</span> */}
                                    <h4>
                                         {item.boldtext.length > 30
                                        ? `${item.boldtext.substring(0, 30)}...`
                                        : item.boldtext}
                                </h4>
                                    <p>{item.text.length > 80 ? `${item.text.substring(0, 80)}...` : item.text}</p>
                                       <a href={`/newsfull?boldtext=${item.boldtext}`} class="boxed-btn5">Apply NOw</a>
                                    
                                </div>
                            </div>
                        </div>
                         ))}
                        {/* <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/3.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Bio engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                         <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                </div>

           
        </div>
    </div>
    </div>
       
      </>
    );
};
export default News;