import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
// import "./Cards.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";


const News = () => {
  const [news, setNews] = useState([]);

  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/news/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNews(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);

  AOS.init();
  AOS.refresh();
  return (
    <>
      {news.map((news) => {
        return (
          <div>
            <div style={{ background: "#fff", height: "90px" }}>
              <h1
                className="title_uz"
                style={{
                  color: "#06BBCC",
                  fontSize: "1.5rem",
                  textTransform: "uppercase",
                  
                  paddingTop: "30px",
                }}
              >
                __________ {news.name} __________
              </h1>
            </div>
          </div>
        );
      })}
    </>
  );
};
const NewsIn = () => {
//   const [news, setNews] = useState([]);

//   const lang = localStorage.getItem("lang") || "uz";
//   useEffect(() => {
//     fetch(`https://science.nuu.uz/${lang}/application/newsfull/`)
//       .then((response) => response.json())
//       .then((data) => {
//         console.log(data);
//         setNews(data);
//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
//   }, [lang]);

  AOS.init();
  AOS.refresh();
  return (
    <>
      <div>
        <div style={{ background: "rgb(37, 39, 52)", height: "90px", width: "100%" }}></div>
        <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/1.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Chemical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/3.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Bio engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                         <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
      </div>
    </>
  );
};

const NewsFull = () => {
    const [searchParam] = useSearchParams();
    const [news, setNews] = useState([]);
  
    const lang = localStorage.getItem("lang") || "uz";
  
    useEffect(() => {
      fetch(`https://science.nuu.uz/${lang}/application/newsfull/`)
        .then((response) => response.json())
        .then((data) => {
          const boldtext = searchParam.get("boldtext");
          setNews(data?.filter((user) => user?.boldtext === boldtext));
          console.log("newsfull")
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, [lang, searchParam]);
    return (
      <>
        <div style={{height: "90px" }}></div>
        <div className="container">
        
          {news.map((item) => {
            return (
           
                <div
                  className="container"
                  style={{
                    paddingTop: "10px",
                    background: "white",
                    boxShadow: "2px, 3px, 3px, 2px darkgreen",
                  }}
                  key={item.id}
                >
                    
                 <div>
                
                  <br />
                  <div className="ckeditor" dangerouslySetInnerHTML={{ __html: item.function }}></div>
                 </div>
                  
                </div>
              
            );
          })}
        </div>
      </>
    );
  };
  
  export default NewsFull;
  
export { News, NewsIn, NewsFull };

