import React, { useEffect, useState } from "react";
// import { Card } from "react-bootstrap";
// import "./Cards.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Carousel from './Carousel'
// import Leadership from './Leadership'
// import Projects from './Projects'
// import Counsil from './Counsil'
// import Confrence from './Confrence'
// import Talent from './Talent'
import Footer from './Footer'
// import News from './News'

export const GiveTranslate = (data) => {
  const lang = localStorage.getItem("lang") || "uz";
  return lang === "en"
    ? data?.name_en
    : lang === "ru"
    ? data?.name_ru
    : data?.name_uz;
};

const Home = () => {
    const lang = localStorage.getItem("lang") || "uz";
    const [newstop, setNewsTop] = useState([]);
    const [news, setNews] = useState([]);
    const [talent, setTalents] = useState([]);
    const [project, setProject] = useState([]);
  

    useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/news/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNewsTop(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);


   
    useEffect(() => {
      fetch(`https://science.nuu.uz/${lang}/application/newsfull/`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setNews(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, [lang]);
  
    AOS.init();
    AOS.refresh();

   

  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/talentfull/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("talent");
        setTalents(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);
  

  
    useEffect(() => {
      fetch(`https://science.nuu.uz/${lang}/application/projecttype/`)
        .then((response) => response.json())
        .then((data) => {
          console.log('loyiha');
          setProject(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }, [lang]);
    

  return (
    <div>
        <Carousel/>
        <div>
        <div class="popular_program_area section__padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section_title text-center">
    {newstop.map((news) => {
        return (
          
            <div className="mb-5">
              <h3 className="head" style={{marginBottom: '30px'}}>{news.name}</h3>
            </div>
          
        );
      })}
                    </div>
                </div>
            </div>
            {/* <div class="row">
                <div class="col-lg-12">
                    <nav class="custom_tabs text-center">
                        <div class="nav" id="nav-tab" role="tablist">
                            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="/nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Graduate                                </a>
                            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="/nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Postgraduate </a>
                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="/nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">PHD Scholarships</a>
                            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="/nav-contact2" role="tab" aria-controls="nav-contact" aria-selected="false">Training</a>
                        </div>
                    </nav>
                </div>
            </div> */}
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="row">
                         {news.slice(Math.max(news.length - 4, 1)).reverse().map((item) => (
                        <div class="col-lg-3 col-md-6" key={item.id}>
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src={item.icon} alt="" style={{maxHeight: "180px"}}/>
                                </div>
                                <div class="program__content">
                                    {/* <span>Agriculture</span> */}
                                    <h4>
                                         {item.boldtext.length > 30
                                        ? `${item.boldtext.substring(0, 30)}...`
                                        : item.boldtext}
                                </h4>
                                    <p>{item.text.length > 80 ? `${item.text.substring(0, 80)}...` : item.text}</p>
                                    <a href={`/newsfull?boldtext=${item.boldtext}`} class="boxed-btn5">View</a>
                                    
                                </div>
                            </div>
                        </div>
                         ))}
                        {/* <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/3.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Bio engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div>
                         <div class="col-lg-3 col-md-6">
                            <div class="single__program">
                                <div class="program_thumb">
                                    <img src="img/program/2.png" alt=""/>
                                </div>
                                <div class="program__content">
                                    <span>Agriculture</span>
                                    <h4>Mechanical engneering</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                                    <a href="/" class="boxed-btn5">Apply NOw</a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="course_all_btn text-center">
                        <a href="/newsAll" class="boxed-btn4">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
        {/* <News/> */}
       <div class="latest_coures_area">
        <div class="latest_coures_inner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="coures_info">
                            <div class="section_title white_text">
                                <h3>Konferensiyalar</h3>
                                <p>Mirzo Ulug‘bek nomidagi <br/> O‘zbekiston milliy universiteti  ishtirokida<br/> o‘tkazilgan konferensiyalar</p>
                            </div>
                            <div class="coures_wrap d-flex">
                                <div class="single_wrap">
                                    <div class="icon">
                                        <i class="flaticon-lab"></i>
                                    </div>
                                    <h4>Ilmiy ishlar <br/>
                                        va innovatsiyalar</h4>
                                        <p>Konferensiyalar haqida to‘liq ma‘lumot olish uchun pastdagi tugmani bosing!</p>
                                        <a href="/confrence" class="boxed-btn5">View</a>
                                </div>
                                {/* <div class="single_wrap">
                                    <div class="icon">
                                        <i class="flaticon-lab"></i>
                                    </div>
                                    <h4>Bachelor of <br/>
                                        Graphic Design</h4>
                                        <p>Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tpor incididunt ut piscing vcs.</p>
                                        <a href="#" class="boxed-btn5">Apply NOw</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
          <div class="recent_event_area section__padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="section_title text-center mb-70">
                        <h3 class="mb-45">Ilmiy-loyihalar</h3>
                        <p>Universitetda amalga oshirilayotgan loyihalar to‘plami.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    {project.map((loyiha) => {
                        return(
                             <div class="single_event d-flex align-items-center">
                        <div class="date text-center">
                            <span><img width={120} height={80} src="https://static.vecteezy.com/system/resources/previews/026/567/255/non_2x/people-work-in-a-team-development-of-business-project-from-idea-to-goal-startup-concept-free-png.png"/></span>
                            <p></p>
                        </div>
                        <div class="event_info">
                                <Link to={`/loyiha/${loyiha?.id}`}>
                                <h4>{loyiha.name}</h4>
                                </Link>
                           
                            <p><span> <i class="flaticon-clock"></i> 10:30 pm</span> <span> <i class="flaticon-calendar"></i> 21Nov 2020 </span> <span> <i class="flaticon-placeholder"></i> AH Oditoriam</span> </p>
                        </div>
                    </div>
                        )
                    })}
                   
                    {/* <div class="single_event d-flex align-items-center">
                        <div class="date text-center">
                            <span>02</span>
                            <p></p>
                        </div>
                        <div class="event_info">
                            <a href="event_details.html">
                                <h4>Amaliy loyihalar</h4>
                             </a>
                            <p><span> <i class="flaticon-clock"></i> 10:30 pm</span> <span> <i class="flaticon-calendar"></i> 21Nov 2020 </span> <span> <i class="flaticon-placeholder"></i> AH Oditoriam</span> </p>
                        </div>
                    </div>
                    <div class="single_event d-flex align-items-center">
                        <div class="date text-center">
                            <span>03</span>
                            <p></p>
                        </div>
                        <div class="event_info">
                            <a href="event_details.html">
                                <h4>Innovatsion loyihalar</h4>
                             </a>
                            <p><span> <i class="flaticon-clock"></i> 10:30 pm</span> <span> <i class="flaticon-calendar"></i> 21Nov 2020 </span> <span> <i class="flaticon-placeholder"></i> AH Oditoriam</span> </p>
                        </div>
                    </div>
                    <div class="single_event d-flex align-items-center">
                        <div class="date text-center">
                            <span>04</span>
                            <p></p>
                        </div>
                        <div class="event_info">
                            <a href="event_details.html">
                                <h4>Xalqaro loyihalar</h4>
                             </a>
                            <p><span> <i class="flaticon-clock"></i> 10:30 pm</span> <span> <i class="flaticon-calendar"></i> 21Nov 2020 </span> <span> <i class="flaticon-placeholder"></i> AH Oditoriam</span> </p>
                        </div>
                    </div>
                    <div class="single_event d-flex align-items-center">
                        <div class="date text-center">
                            <span>05</span>
                            <p></p>
                        </div>
                        <div class="event_info">
                            <a href="event_details.html">
                                <h4>Noyob loyihalar</h4>
                             </a>
                            <p><span> <i class="flaticon-clock"></i> 10:30 pm</span> <span> <i class="flaticon-calendar"></i> 21Nov 2020 </span> <span> <i class="flaticon-placeholder"></i> AH Oditoriam</span> </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
         <div>
        <div data-scroll-index='1' class="admission_area">
        <div class="admission_inner">
            <div class="container">
                <div class="row justify-content-end">
                    <div class="col-lg-7">
                        <div class="coures_info">
                            <div class="section_title white_text">
                                <h3>Ilmiy kengash</h3>
                                <p>Mirzo Ulug‘bek nomidagi <br/> O‘zbekiston milliy universitetida <br/> tashkil etilgan ilmiy kengash faoliyati.</p>
                            </div>
                            <div class="coures_wrap d-flex">
                                <div class="single_wrap">
                                    <div class="icon">
                                        <i class="flaticon-lab"></i>
                                    </div>
                                    {/* <h4>Bachelor of <br/>
                                        Graphic Design</h4>
                                        <p>Lorem ipsum dolor sit amet, sectetur adipiscing elit, sed do eiusmod tpor incididunt ut piscing vcs.</p> */}
                                        <a href="/council" class="boxed-btn5">View</a>
                                </div>
                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
      
    <div>
        <div class="recent_news_area section__padding">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10">
                    <div class="section_title text-center mb-70">
                        <h3 class="mb-45">Iqtidorli talabalar</h3>
                        <p>Iqtidorli talabalar bizning kelajagimiz.</p>
                    </div>
                </div>
            </div>
           <div class="row">
                 {talent.slice(0, 3).reverse().map((items) => (
                <div class="col-md-4" key={items.id}>
                    <div class="single__news">
                        <div class="thumb">
                            <a href="single-blog.html">
                                <img src={items.img} alt="" style={{maxHeight: "350px"}}/>
                            </a>
                            <span class="badge">Group Study</span>
                        </div>
                        <div class="news_info">
                            <a href={`/talentfull?name=${items.name}`}>
                                <h4>
                                    {items.name.length > 70
                            ? `${items.name.substring(0, 70)}...`
                            : items.name}
                             </h4>
                            </a>
                            <p class="d-flex align-items-center"> <span><i class="flaticon-calendar-1"></i> May 10, 2020</span> 
                            
                            <span> <i class="flaticon-comment"></i> 01 comments</span>
                            </p>
                        </div>
                    </div>
                </div>
                 ))}
                {/* <div class="col-md-4">
                    <div class="single__news">
                        <div class="thumb">
                            <a href="single-blog.html">
                                <img src="img/news/2.png" alt=""/>
                            </a>
                            <span class="badge bandge_2">Hall Life</span>
                        </div>
                        <div class="news_info">
                            <a href="single-blog.html">
                                <h4>Those Other College Expenses You
                                    Aren’t Thinking About</h4>
                            </a>
                            <p class="d-flex align-items-center"> <span><i class="flaticon-calendar-1"></i> May 10, 2020</span> 
                            
                            <span> <i class="flaticon-comment"></i> 01 comments</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single__news">
                        <div class="thumb">
                            <a href="single-blog.html">
                                <img src="img/news/2.png" alt=""/>
                            </a>
                            <span class="badge bandge_2">Hall Life</span>
                        </div>
                        <div class="news_info">
                            <a href="single-blog.html">
                                <h4>Those Other College Expenses You
                                    Aren’t Thinking About</h4>
                            </a>
                            <p class="d-flex align-items-center"> <span><i class="flaticon-calendar-1"></i> May 10, 2020</span> 
                            
                            <span> <i class="flaticon-comment"></i> 01 comments</span>
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="course_all_btn text-center">
                        <a href="/talent" class="boxed-btn4">View All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
        <Footer/>
    </div>
  )
}

export default Home