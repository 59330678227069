import React, { useState, useEffect } from "react";
import { Link, useParams,useSearchParams } from "react-router-dom";
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


  const [navSize, setnavSize] = useState("8rem");
  const [navColor, setnavColor] = useState("transparent");
  const [navShadow, setnavShadow] = useState("transparent");
  const [navHeight, setnavHeight] = useState("3rem");
  // const listenScrollEvent = () => {
  //   window.scrollY > 10 ? setnavColor("#fff") : setnavColor("transparent");
  //   window.scrollY > 10 ? setnavShadow("") : setnavShadow("transparent");
  //   window.scrollY > 10 ? setnavSize("5rem") : setnavSize("8rem");
  //   window.scrollY > 10 ? setnavHeight("0rem") : setnavHeight("4rem");
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", listenScrollEvent);
  //   return () => {
  //     window.removeEventListener("scroll", listenScrollEvent);
  //   };
  // }, []); 

  const [posts, setPosts] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/menu/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("sadsad", data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);

  

  return (
    <div>
         <header>
        <div class="header-area">
            <div class="header-top_area">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="header_top_wrap d-flex justify-content-between align-items-center">
                                <div class="text_wrap">
                                    <p><span>+880166 253 232</span> <span>info@domain.com</span></p>
                                </div>
                                <div class="text_wrap">
                                   <a href="#"><i class="fa fa-telegram" aria-hidden="true"></i></a> 
                                   <a href="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                                   <a href="#"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                                   <a href="#"><i class="fa fa-" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </div>
    </header>
    
       <div className="container">
      <nav className="navbar">
            <div className="logo" style={{display: "flex"}}><img src="https://upload.wikimedia.org/wikipedia/commons/4/4e/National_University_of_Uzbekistan_Logo.png" width={70} height={70} style={{marginRight: "10px"}}/><div>Ilmiy-ishlar va innovatsiyalar</div></div>
            <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
                 {posts.map((post) => {
                return (
                  <li key={post.id}>
                    <Link to={post.url} >
                      {post.name}
                    </Link>
                    <input type="checkbox" id="drop-1" />
                    <ul>
                      {post.submenu &&
                        post.submenu.map((data) => (
                          <li key={data.id}>
                            <Link to={data.url} >
                              {data.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                );
              })}

              <li>
              <div class="language-select">
               {/* <select id="langSelect">
                  <option value="uz" onClick={() => {
                    localStorage.setItem("lang", "uz");
                    window.location.reload();
                  }}><button
                  className="btn"
                  style={{
                    background: "white",
                    border: "2px solid #4CAF50",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("lang", "uz");
                    window.location.reload();
                  }}
                >
                  UZ
                </button>
                </option>
                  <option value="en" onClick={() => {
                    localStorage.setItem("lang", "en");
                    window.location.reload();
                  }}>EN</option>
                  <option value="ru" onClick={() => {
                    localStorage.setItem("lang", "ru");
                    window.location.reload();
                  }}>RU</option>
              </select> */}
            </div>
            
                {/* <button
                  className="btn"
                  style={{
                    background: "white",
                    border: "2px solid #4CAF50",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("lang", "uz");
                    window.location.reload();
                  }}
                >
                  UZ
                </button>
                <button
                  className="btn"
                  style={{
                    background: "white",
                    border: "2px solid #4CAF50",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    window.location.reload();
                  }}
                >
                  EN
                </button>
                <button
                  className="btn"
                  style={{
                    background: "white",
                    border: "2px solid #4CAF50",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    localStorage.setItem("lang", "ru");
                    window.location.reload();
                  }}
                >
                  RU
                </button> */}
              </li>
            </ul>
            <div className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </nav>
       </div>
     <div class="test-mode-banner">
    Sayt test rejimida ishlamoqda
  </div>
    </div>
    
  )
}


export default Header;
