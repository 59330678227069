import React from 'react'

const Footer = () => {
  return (
    <div>
        <footer class="footer">
        <div class="footer_top">
            <div class="container">
                
                <div class="row">
                    <div class="col-xl-3 col-md-6 col-lg-3">
                        <div class="footer_widget">
                            <h3 class="footer_title">
                                Biz haqimizda
                            </h3>
                            <ul>
                                <li><a href="#">Online Learning</a></li>
                                <li><a href="#">About Us</a></li>
                                <li><a href="#">Careers</a></li>
                                <li><a href="#">Press Center</a></li>
                                <li><a href="#">Become an Instructor</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6 col-lg-3">
                        <div class="footer_widget">
                            <h3 class="footer_title">
                                Foydali havolalar
                            </h3>
                            <ul>
                                <li><a href="#">Oliy ta‘lim, fan va innovatsiyalar vazirligi</a></li>
                                <li><a href="#">Universitet rasmiy sayti</a></li>
                                <li><a href="#">Universitet qabuli</a></li>
                                <li><a href="#">Bilim va malakalarni baholash agentligi</a></li>
                                <li><a href="#">Doktorantura</a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="col-xl-3 col-md-6 col-lg-3">
                        <div class="footer_widget">
                            <h3 class="footer_title">
                                Bo‘glanish
                            </h3>
                            <ul>
                                <li><a href="#">Toshkent, NY 10012, UZ</a></li>
                                <li><a href="#">info@nuu.com</a></li>
                                <li><a href="#">System Requirements</a></li>
                                <li><a href="#">+998 (71) 246-63-58</a></li>
                                <li><a href="#">+ 01 234 567 89</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6 col-lg-3">
                        <div class="footer_widget">
                            <h3 class="footer_title">
                                Joylashuv
                            </h3>
                            <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.0909881132675!2d69.2031524764383!3d41.35037599828961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8c3d5af08d2f%3A0x4f6c8b4d4847f94d!2sNational%20University%20of%20Uzbekistan!5e0!3m2!1sen!2s!4v1722406122684!5m2!1sen!2s" width="320" height="200" style={{border:"0", allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade"}}></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copy-right_text">
            <div class="container">
                <div class="footer_border">
                    <p className='text-center'>Mirzo Ulug‘bek nomidagi O‘zbekiston Milliy Universiteti Ilmiy ishlar va innovatsiyalar bo‘limi</p>
                </div>
                
            </div>
        </div>
    </footer>
    </div>
  )
}

export default Footer