import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Confrence = () => {

    const [conference, setConference] = useState([]);

     const lang = localStorage.getItem("lang") || "uz";
    useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/conference/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setConference(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
     }, [lang]);
  return (
  <div>
     <section class="blog_area section-padding">
        <div class="container">
            <div class="row">
                 {conference.map((confe) => {
                        return (
                <div class="col-lg-6 mb-5 mb-lg-0">
                    <div class="">
                      
                       
                        <article class="blog_item" key={confe.id} style={{display: "flex"}}>
                            <div class="blog_item_img">
                                <img class="card-img rounded-0" src={confe.image_file} alt="" style={{maxHeight: "410px", maxWidth: "350px"}}/>
                                {/* <a href="#" class="blog_item_date">
                                    <h3></h3>
                                    <p>{confe.full_date}</p>
                                </a> */}
                            </div>

                            <div class="blog_details" style={{alignContent: "space-between"}}>
                                <a class="d-inline-block" href={`/conferencefull?name=${confe.name}`}>
                                 
                                 <h2 style={{fontSize: "20px", maxWidth: "400px"}}>{confe.name}</h2>
                                </a>
                                {/* <p>{confe.info}</p> */}
                                <ul class="blog-info-link">
                                    <li><i class="fa fa-clock-o" aria-hidden="true"></i> <b>{confe.info}</b></li> 
                                    <li><span> {confe.full_date}</span></li> <br/>
                                    <li><i class="fa fa-language" aria-hidden="true"></i><b> {confe.language}</b></li>
                                    <li><span> {confe.language_type}</span></li>
                                </ul>
                            </div>
                        </article>
                        
                        {/* <article class="blog_item">
                            <div class="blog_item_img">
                                <img class="card-img rounded-0" src="img/blog/single_blog_2.png" alt=""/>
                                <a href="#" class="blog_item_date">
                                    <h3>15</h3>
                                    <p>Jan</p>
                                </a>
                            </div>

                            <div class="blog_details">
                                <a class="d-inline-block" href="single-blog.html">
                                    <h2>Google inks pact for new 35-storey office</h2>
                                </a>
                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                    he earth it first without heaven in place seed it second morning saying.</p>
                                <ul class="blog-info-link">
                                    <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                    <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                </ul>
                            </div>
                        </article>
                        */}
                    </div>
                </div>
                  );
                         })}
                {/* <div class="col-lg-6 mb-5 mb-lg-0">
                    <div class="">
                        <article class="blog_item">
                            <div class="blog_item_img">
                                <img class="card-img rounded-0" src="img/blog/single_blog_1.png" alt=""/>
                                <a href="#" class="blog_item_date">
                                    <h3>15</h3>
                                    <p>Jan</p>
                                </a>
                            </div>

                            <div class="blog_details">
                                <a class="d-inline-block" href="single-blog.html">
                                    <h2>Google inks pact for new 35-storey office</h2>
                                </a>
                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                    he earth it first without heaven in place seed it second morning saying.</p>
                                <ul class="blog-info-link">
                                    <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                    <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                </ul>
                            </div>
                        </article>

                        <article class="blog_item">
                            <div class="blog_item_img">
                                <img class="card-img rounded-0" src="img/blog/single_blog_2.png" alt=""/>
                                <a href="#" class="blog_item_date">
                                    <h3>15</h3>
                                    <p>Jan</p>
                                </a>
                            </div>

                            <div class="blog_details">
                                <a class="d-inline-block" href="single-blog.html">
                                    <h2>Google inks pact for new 35-storey office</h2>
                                </a>
                                <p>That dominion stars lights dominion divide years for fourth have don't stars is that
                                    he earth it first without heaven in place seed it second morning saying.</p>
                                <ul class="blog-info-link">
                                    <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                                    <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                                </ul>
                            </div>
                        </article>

                       
                    </div>
                </div> */}
               
            </div>
        </div>
    </section>
    </div>
  )
}

export default Confrence;

 