import React, { useEffect, useState } from "react";
import "./IlmiyMuass.css";
import { Link, useParams,useSearchParams } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";


const IlmiyMuassasa = () => {
  const { id } = useParams([]);
  const [posts, setPosts] = useState([]);
  const [xalq, setXalq] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/ilmiy-t_muass/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("muass",data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);

 
  return (
    <>
      <div style={{height: "90px" }}></div>
      
       
      <div className="container">
      <div style={{border: "1px solid darkgrey", marginTop: "20px"}}></div>
      <div className="row" style={{margin: "20px"}}>
       {posts.map((item) => {
        return (
          <div className="col-md-4 m-1">
         
          <div class="card" style={{width: "24rem", height: "10rem", borderTop: "4px solid", borderColor: "#2ed3ae"}}>
          <div class="card-body">
            
            <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Link to={`/ilmiy_muassfull?name=${item.name}`}>
            <strong class="card-texts" style={{color: "black", fontSize: "13px"}}>{item.short_name}</strong>
            </Link>
             
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="80" height="80" viewBox="0 0 48 48">
          <circle cx="24" cy="24" r="20" fill="#669df6"></circle><path fill="#317be4" d="M42,27v5.73C38.76,39.4,31.92,44,24,44C12.95,44,4,35.05,4,24c0-2.09,0.32-4.1,0.92-6H33	C37.97,18,42,22.03,42,27z"></path><path fill="#fff" d="M30,15v8.08C29.67,23.02,29.34,23,29,23c-1.08,0-2.09,0.23-3,0.66V15c0-1.1,0.9-2,2-2S30,13.9,30,15z"></path><path fill="#fff" d="M24,22v3.06c-1.24,1.26-2,3-2,4.94c-1.1,0-2-0.9-2-2v-6c0-1.1,0.9-2,2-2S24,20.9,24,22z"></path><path fill="#fff" d="M16,30c-1.104,0-2-0.896-2-2v-1c0-1.104,0.896-2,2-2s2,0.896,2,2v1C18,29.104,17.104,30,16,30z"></path><path fill="#fff" d="M35,34.6l-1.8-1.8C33.7,32,34,31,34,30c0-2.8-2.2-5-5-5s-5,2.2-5,5s2.2,5,5,5c1,0,2-0.3,2.8-0.8	l1.8,1.8L35,34.6z M29,33c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S30.7,33,29,33z"></path>
          </svg>
            </div>
          </div>
                    
        </div>
       
       
        </div>
        )
       })}
       
       </div>
       <div style={{border: "1px solid darkgrey", marginBottom: "20px"}}></div>
      </div>
    </>
  );
};


const IlmiyMuasFull = () => {
  //   const [active, setActive] = useState(types[0]);]

  const [searchParam] = useSearchParams();
  const [id] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";

  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/ilmiy-t_muass/`)
      .then((response) => response.json())
      .then((data) => {
        const name = searchParam.get("name");
        setPosts(data?.filter((user) => user?.name === name));
        console.log("2222")
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, searchParam]);

  return (
    <>
      <div style={{height: "90px" }}></div>
      
      <div className="container">
      {posts.map((item) => {
        return (
      <>
      <div style={{border: "1px solid darkgrey", marginTop: "20px"}}></div>
      <div className="row" style={{margin: "20px"}}>
        <div className="col-md-4">
       <div  style={{float: "center"}}>
       <h1>{item.short_name}</h1>
        <img src={item.icon} style={{maxHeight: "300px",maxWidth: "300px"}}/>
       </div>
        </div>
                           
       </div>
       <div style={{border: "1px solid darkgrey", marginTop: "20px"}}></div>
       <div className="text-k" key={item.id} dangerouslySetInnerHTML={{ __html: item.function }}></div>
                   <div style={{margin: "5px",padding: "5px"}}>
                   <button className="span-border" style={{border: "4px", background: "linear-gradient(to left, turquoise, greenyellow)", borderRadius: "5px", padding: "5px",margin: "5px"}}>
                   <a href={item.pdf}>
                        <b style={{ color: "blue" }}>
                          Buyruq <AiOutlineFilePdf />
                        </b>
                      </a>
                   </button>
                      
                   <button className="span-border" style={{border: "4px", background: "linear-gradient(to left, turquoise, greenyellow)", borderRadius: "5px", padding: "5px",margin: "5px"}}>
                   <a href={item.pdf}>
                        <b style={{ color: "blue" }}>
                          Buyruq <AiOutlineFilePdf />
                        </b>
                      </a>
                   </button>
                      <br/>
                      <div style={{color: "blue", margin: "5px"}}><a href={item.url} style={{color: "blue"}}>To‘liq ma‘lumot saytda</a></div>
                   </div>
       <div style={{border: "1px solid darkgrey", marginBottom: "20px"}}></div>
      </>
        )
      })}
       
      </div>
    </>
  );
};

export  {IlmiyMuassasa, IlmiyMuasFull};


