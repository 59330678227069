import React, { useEffect, useState } from "react";
import { Link, useParams,useSearchParams } from "react-router-dom";

const Projects = () => {
     //   const [active, setActive] = useState(types[0]);]
  const { id } = useParams([]);
  const [posts, setPosts] = useState([]);
  const [xalq, setXalq] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/loyiha/${id}/`)
      .then((response) => response.json())
      .then((data) => {
        console.log("111",data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);
  return (
        
    <div>
         <div class="event_details_area section__padding">
            <div style={{height: "90px" }}></div>
        <div class="container">
            <div class="row">
           
                {posts.map((item) => {
        return (
                <div class="col-md-6">
                    <div class="single_event d-flex align-items-center" style={{padding: "6px"}}>
                        <div class="thumb">
                            <img src="https://mir-s3-cdn-cf.behance.net/projects/max_808/89f6cb29340175.Y3JvcCwyNDU3LDE5MjIsNDUwLDM1OA.jpg" alt="" />
                            {/* <div class="date text-center">
                                <h4>02</h4>
                                <span>Dec, 2020</span>
                            </div> */}
                        </div>
                        <div class="event_details_info">
                            <div class="event_info">
                                 <Link to={`/projectfull?name=${item.name}`}>
                                 <h4>
                                         {item.name.length > 80
                                        ? `${item.name.substring(0, 80)}...`
                                        : item.name}
                                </h4>
                                </Link>
                               
                            </div>
                            <p class="event_info_text">{item.leader} </p>
                            <p class="event_info_text">{item.project_number} </p>
                            <p class="event_info_text">{item.date} </p>
                            <a href={`/projectfull?name=${item.name}`} class="boxed-btn3">{}View</a>
                        </div>
                    </div>
                </div>
                 )
       })}
                {/* <div class="col-md-6">
                    <div class="single_event d-flex align-items-center">
                        <div class="thumb">
                            <img src="img/event/1.png" alt=""/>
                            <div class="date text-center">
                                <h4>02</h4>
                                <span>Dec, 2020</span>
                            </div>
                        </div>
                        <div class="event_details_info">
                            <div class="event_info">
                                <a href="#">
                                    <h4>How to speake like a nativespeaker?</h4>
                                 </a>
                                
                            </div>
                            <p class="event_info_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In rhoncus augue nibh, at ullamcorper orci ullamcorper ut. 
                            </p>
                            <a href="#" class="boxed-btn3">Book a seat</a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    </div>


   
  )
}

export default Projects;

 