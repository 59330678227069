import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

const TalentFull = () => {
   const [searchParam] = useSearchParams();
  const [talents, setTalentsFull] = useState([]);

  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/talentfull/`)
      .then((response) => response.json())
      .then((data) => {
        const name = searchParam.get("name");
        setTalentsFull(data?.filter((user) => user?.name === name));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, searchParam]);

  return (
    <div>
      <div style={{height: "90px" }}></div>
        <section class="blog_area single-post-area section-padding">
      <div class="container">
         <div class="row">
            {talents.map((item) => {
          return (
            <div class="col-lg-12 posts-list">
               <div class="single-post">
                  <div class="feature-img">
                     <img class="img-fluid" src={item.img} alt=""/>
                  </div>
                  <div class="blog_details">
                     <h2>{item.name}
                     </h2>
                     <ul class="blog-info-link mt-3 mb-4">
                        <li><a href="#"><i class="fa fa-user"></i> Travel, Lifestyle</a></li>
                        <li><a href="#"><i class="fa fa-comments"></i> 03 Comments</a></li>
                     </ul>
                     <p class="excert">
                        {item.text}
                     </p>
                      <div className="ckeditor" dangerouslySetInnerHTML={{ __html: item.function }}></div>

                     
                  </div>
               </div>
               
            </div>
              );
        })}
         </div>
      </div>
   </section> 
    </div>
  )
}

export default TalentFull;