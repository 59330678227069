
import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import './Carousel.css';

const Carousel1 = () => {

  const [caro, setCaro] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/topcarousel/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setCaro(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang]);

  return (
  
  <>
    <Carousel slide={true}>
          {caro.map((carousel) => {
            return (
              <Carousel.Item key={carousel.id}>
                <div className="carousel-item active">
                  <img className="w-100" src={carousel.img} alt="" />
                </div>
                <Carousel.Caption>
                   <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="slider_text ">
                                <h3>Mirzo Ulug‘bek nomidagi
                                    O‘zbekiston Milliy Universiteti<br/>
                                   </h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
                  {/* <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{ maxWidth: "950px" }}>
                      <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                        {carousel.smalltext}
                      </h5>
                      <h4 className="display-1 text-white mb-md-4 animated zoomIn">
                        {carousel.bigtext}
                      </h4>
                      <p>{carousel.button}</p>
                    </div>
                  </div> */}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
  </>
  )
}

export default Carousel1;