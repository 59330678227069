import React, { useEffect, useState } from "react";
// import styled from 'styled-components';
// import Table from "react-bootstrap/Table";
// import "./TabSelector.css";
// import { post } from "jquery";
import { Link, useParams,useSearchParams } from "react-router-dom";
// import { AiOutlineFilePdf } from "react-icons/ai";

const ProjectFull = () => {
    const [searchParam] = useSearchParams();
  const [id] = useSearchParams();
  const [posts, setPosts] = useState([]);
  const lang = localStorage.getItem("lang") || "uz";

  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/project/`)
      .then((response) => response.json())
      .then((data) => {
        const name = searchParam.get("name");
        setPosts(data?.filter((user) => user?.name === name));
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, searchParam]);

  return (
  <>
      <div style={{height: "90px" }}></div>
      
       
      
      <div className="container">
      {posts.map((item) => {
        return (
      <>
      <div style={{border: "1px solid darkgrey", marginTop: "20px"}}></div>
      <div className="row" style={{margin: "20px"}}>
        <div className="col-md-4">
       <div  style={{float: "center"}}>
       <h1>{item.short_name}</h1>
        <img src="https://st.spacecom.uz//cache/7/1/a/d/f/71adf1afa90f9811d46d141c3e71b3ca854edddc.png" style={{maxHeight: "300px",maxWidth: "300px"}}/>
       </div>
        </div>
        <div className="col-md-8" >
        <div  style={{float: "left"}}>
        <h1>{item.name}</h1>
        <div style={{float: "left"}}>
        <p  style={{float: "left"}}> <span style={{fontWeight: "600"}}>Loyiha Rahbari: </span>{item.leader}</p>
        <br/>
        <p  style={{float: "left"}}> <span style={{fontWeight: "600"}}>Loyiha Shifri:  </span>{item.project_number}</p>
        <br/>
        <p  style={{float: "left"}}> <span style={{fontWeight: "600"}}>Loyiha Muddati:  </span>{item.date}</p>
        </div>
        </div>
      </div>
                   
       </div>
       <div style={{border: "1px solid darkgrey", marginTop: "20px"}}></div>
       <div
                      className="text-k"
                      
                      key={item.id}
                      dangerouslySetInnerHTML={{ __html: item.full }}
                    ></div>
                   <a href={item.pdf}>
                        <b style={{ color: "blue" }}>
                          {/* PDF <AiOutlineFilePdf /> */}
                        </b>
                      </a>
       <div style={{border: "1px solid darkgrey", marginBottom: "20px"}}></div>
      </>
        )
      })}
       
      </div>
    </>
  )
}

export default ProjectFull