import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";


const ConferenceFull = () => {
     const [searchParam] = useSearchParams();
    const [conference, setConferences] = useState([]);
  
    const lang = localStorage.getItem("lang") || "uz";
  useEffect(() => {
    fetch(`https://science.nuu.uz/${lang}/application/conference/`)
      .then((response) => response.json())
      .then((data) => {
        const name = searchParam.get("name");
        setConferences(data?.filter((user) => user?.name === name));
        console.log("confefull")
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [lang, searchParam]);
  return (
    <div>
      <div className="container">
        <div style={{height: "90px" }}></div>
    {conference.map((confe) => {
                return (

            <div key={confe.id}>
                <div className="row">
                    <div className="col-md-4">
                    <img src={confe.image_file} alt="" style={{width:"140", height:"210", float:"right"}} />
                    </div>
                    <div className="col-md-8">
                    <div className="conference" style={{color: "black", textAlign: "left", float:"left", }}>
                    <p style={{textAlign: "left"}}>
                    {confe.name}
                    </p>

                    <div className="mt-4">
                    <p style={{color:"black", fontWeight: "bold", textAlign: "left"}}>{confe.info}</p>
                    <p style={{textAlign: "left"}}>{confe.full_date}</p>
                    <p style={{color:"black", fontWeight: "bold", textAlign: "left"}}>{confe.language}</p>
                    <p style={{textAlign: "left"}}>{confe.language_type}</p>
                    </div>
                    </div>
                    </div>
                    
                </div>
                <div style={{fontSize: "16px"}} dangerouslySetInnerHTML={{ __html: confe.function }}></div>

                    <div style={{textAlign: "start", marginTop: "120px"}}>

                    <br/>
                    <button className="span-border" style={{border: "4px",  borderRadius: "5px", padding: "5px",margin: "5px"}}>
                   <a href={confe.pdf}>
                        <b style={{ color: "blue" }}>
                          Konferensiya to‘plami <AiOutlineFilePdf />
                        </b>
                      </a>
                   </button>
                      
                   <button className="span-border" style={{border: "4px",  borderRadius: "5px", padding: "5px",margin: "5px"}}>
                   <a href={confe.pdf}>
                        <b style={{ color: "blue" }}>
                        Konferensiya hisoboti <AiOutlineFilePdf />
                        </b>
                      </a>
                   </button>
                  <div>
                  <video controls loop muted style={{margin: '20', maxWidth: '600', maxHeight:'600'}}>
                 <source src={confe.video} type="video/mp4"/>
                   </video>
                    </div>
                  <div className="summary" style={{margin: "40px", float: "left"}}>
                       
                              <details style={{border: "1px solid darkgrey", padding: "10px 20px"}}>
                                <summary style={{color: "black", fontSize: "16px"}}>{confe.materials}</summary>
                                {confe.add_article &&
                          confe.add_article.map((article) => {
                            return (
                               <div style={{border: "1px solid darkgrey", padding: "10px 20px"}}>
                                <span style={{color: "black", fontSize: "16px"}}>{article.confmaterial}</span>
                                 <br/>
                                <span style={{color: "black", fontSize: "16px"}}>{article.author}</span>
                                 <br/>
                                <span style={{color: "black",fontSize: "16px"}}>{article.time}</span>
                                <br/>
                                <span>
                                <a href={article.url} style={{color:"blue",fontSize: "16px"}}>{article.url}</a>
                                 </span>
                                                               
                               </div>

                              );
                            })}
                              </details>
                           
                      </div>
                    
                        
                    </div>
            </div>
            
        );
        })}
      </div>
        
    </div>
  )
}

export default ConferenceFull;